import React from 'react';
import PrivacyContent from '../components/PrivacyContent';

const Privacy: React.FC = () => {
  return (
    <>
      <PrivacyContent />
    </>
  );
};

export default Privacy;
