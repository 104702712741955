import React from 'react';
import sectionImage from '../assets/images/section-image-edge-forge.png';

const TwoColumnSection: React.FC = () => {
  return (
    <section className="relative bg-[#101010] py-20 flex flex-col md:flex-row items-center justify-between max-w-[1600px] mx-auto overflow-visible">

      {/* Left Column (Image) */}
      <div className="xs:w-[100%] md:w-1/2 flex justify-center mt-0 md:mt-0 relative">
        <img
          src={sectionImage}
          alt="Section Image"
          className="w-full h-[300px] sm:h-[600px] md:h-[300px] max-w-md object-contain md:object-cover relative md:absolute md:left-0 md:-top-[130px]"
          style={{ overflow: 'visible' }}
        />
      </div>

      {/* Right Column */}
      <div className="xs:w-[100%] md:w-1/2 text-white space-y-5 z-[10] flex md:block justify-center items-center text-center md:text-left flex-col">
        <h1 className="text-center md:text-left font-nexa-heavy font-[800] text-[26px] sm:text-[64px] md:text-[48px] leading-[31px] sm:leading-[76px] md:leading-[58px] text-[#DCDCDC]">
          Edge Forge’s<br />
          Protocol
        </h1>
        <hr className="flex border-t-2 border-white/25 w-[340px] my-4" />
        <p className="font-nexa-light font-[800] text-[14px] sm:text-[21px] md:text-[16px] text-[#DCDCDC]/75 leading-[17px] sm:leading-[25px] md:leading-[20px] w-[80%] sm:w-[70%] md:w-[400px] ">
          Edge Forge's protocol, fortified by robust research portals, esteemed financial journals, prestigious universities, and influential conferences, embarks on an exhaustive journey of investigating thousands of financial papers.
        </p>
        <p className="font-nexa-light font-[800] text-[14px] sm:text-[21px] md:text-[16px] text-[#DCDCDC]/75 leading-[17px] sm:leading-[25px] md:leading-[20px] w-[80%] sm:w-[70%] md:w-[400px] ">
          Rigorously vetted, only the most promising strategies earn inclusion, while distinctive characteristics extracted from selected strategies enrich and fortify Edge Forge, our paramount platform for unparalleled financial insight.
        </p>
      </div>
    </section>
  );
};

export default TwoColumnSection;
