import { Send } from 'lucide-react';
import React from 'react';

const NewsletterSection: React.FC = () => {
  return (
    <section className="relative z-[1] py-10 my-10 max-w-[1600px] mx-auto px-4 flex flex-col lg:flex-row gap-4 bg-custom-zigzag backdrop-blur-[20px] rounded-[20px] shadow-lg">
      {/* Left Column: Text Content */}
      <div className="hidden lg:flex flex-col p-8 pl-[120px] lg:w-[49%] space-y-6 text-[#DCDCDC]/75">
        <p className="font-nexa-light font-[700] text-[16px] leading-[19px]">
          Don't miss out on winning insights! Subscribe now for top-notch financial research, timely updates, and strategies to boost your success.
        </p>
        <p className="font-nexa-light font-[700] text-[16px] leading-[19px]">
          Stay ahead in the ever-changing market and elevate your win rate. Join us and seize the opportunity for growth and success!
        </p>
        <p className="font-nexa-light font-[700] text-[12px] !text-[#DCDCDC] leading-[12px] !mt-[50px]">
          No spam, and you can unsubscribe anytime.
        </p>
      </div>

      {/* Right Column: Newsletter Signup */}
      <div className="lg:w-[40%] p-8 rounded-lg pl-[45px] pt-[25px] flex flex-col items-center lg:items-start">
        {/* Heading */}
        <h2 className="text-center lg:text-left font-nexa-heavy font-[700] text-[26px] sm:text-[48px] leading-[31px] sm:leading-[58px] text-[#DCDCDC] sm:mb-[35px]">Newsletter</h2>

        {/* Divider */}
        <hr className="hidden md:flex border-t-2 border-[#DCDCDC]/25 w-[50%] lg:w-full mb-[40px]" />

        <div className="flex lg:hidden text-center flex-col p-8 sm:px-[15%] px-[0%] md:px-[30%] lg:w-[49%] space-y-6 text-[#DCDCDC]/75 mb-8">
          <p className="font-nexa-light font-[700] text-[16px] leading-[19px]">
            Don't miss out on winning insights! Subscribe now for top-notch financial research, timely updates, and strategies to boost your success.
          </p>
          <p className="font-nexa-light font-[700] text-[16px] leading-[19px]">
            Stay ahead in the ever-changing market and elevate your win rate. Join us and seize the opportunity for growth and success!
          </p>
          <p className="font-nexa-light font-[700] text-[12px] !text-[#DCDCDC] leading-[12px] !mt-[50px]">
            No spam, and you can unsubscribe anytime.
          </p>
        </div>

        {/* Email Input and Submit Button */}
        <div className="flex items-center mb-4">
          <input
            type="email"
            placeholder="youremail@gmail.com"
            className="flex-grow bg-transparent border-[1px] border-[#DCDCDC] text-[#DCDCDC] px-4 py-2 rounded-l-full placeholder-[#DCDCDC]/50"
          />
          <button className="bg-[#DCDCDC] text-[#101010] font-mentalist font-[700] px-4 py-2 rounded-r-[20px] border-[1px] hover:bg-[#e0e0e0] transition border-[1px] border-[#DCDCDC] hover:bg-transparent hover:text-[#DCDCDC] transition-colors duration-300 hover:border-l-[#DCDCDC00]">
            <Send size={24} />
          </button>
        </div>

        {/* Terms and Conditions Checkbox */}
        <div className="flex items-start space-x-2 !mt-8 sm:px-[20%] md:px-[35%] lg:px-[0px]">
          <input
            type="checkbox"
            id="subscribe-terms"
            className="rounded-full bg-transparent border border-[#DCDCDC] w-5 h-5 cursor-pointer mt-1"
          />
          <label htmlFor="subscribe-terms" className="text-[#DCDCDC]/75 font-nexa-light font-[700] text-[12px] leading-[15px]">
            By subscribing to our newsletter, you agree to our{' '}
            <span className="underline cursor-pointer text-[#DCDCDC]">terms and conditions</span>.
          </label>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSection;
