import React from 'react';
import aiMech from '../assets/images/ai-gen-mech.png';

const ContactUsSection: React.FC = () => {
  return (
    <section id='contact' className="relative bg-[#101010] py-20 flex flex-col xs:flex-col md:flex-row items-center max-w-custom-1600 mx-auto overflow-visible items-center justify-center lg:mb-[110px]">

      {/* Column for background (Image) */}
      <div className="xs:w-[100%] sm:w-1/2 min-h-[350px] flex justify-center mt-10 md:mt-0 relative">
        <div
          className="bg-cover bg-center h-[1300px] w-[1300px] absolute -top-[700px] left-[50px] opacity-[0.15]"
          style={{ backgroundImage: `url(${aiMech})` }}
        ></div>
        <div className='z-[10]'>
          <h2 className="text-center font-nexa-heavy font-[800] text-[32px] sm:text-[48px] text-[#DCDCDC] mb-[30px]">
            Contact us
          </h2>
          {/* Contact Form */}
          <form className="lg:w-[65%] space-y-4 m-auto">
            {/* Row 1: Name and Surname Inputs */}
            <div className="flex flex-row space-x-4">
              <input
                type="text"
                placeholder="Name"
                className="w-[48%] placeholder:text-[12px] bg-transparent border-[1px] border-[#DCDCDC] text-[#DCDCDC] px-4 py-2 rounded-[20px]"
              />
              <input
                type="text"
                placeholder="Surname"
                className="w-[48%] placeholder:text-[12px] bg-transparent border-[1px] border-[#DCDCDC] text-[#DCDCDC] px-4 py-2 rounded-[20px]"
              />
            </div>

            {/* Row 2: Email Input */}
            <input
              type="email"
              placeholder="youremail@gmail.com"
              className="w-full placeholder:text-[12px] bg-transparent border-[1px] border-[#DCDCDC] text-[#DCDCDC] px-4 py-2 rounded-[20px]"
            />

            {/* Row 3: Message Textarea */}
            <textarea
              placeholder="Your message.."
              className="w-full placeholder:text-[12px] bg-transparent border-[1px] border-[#DCDCDC] text-[#DCDCDC] px-4 py-2 pt-4 rounded-[20px] h-[170px]"
            />

            {/* Row 4: Send Button */}
            <button className="w-full bg-[#DCDCDC] text-[#101010] font-mentalist font-[700] py-4 rounded-[40px] text-[12px] border-[1px] border-[#DCDCDC] hover:bg-transparent hover:text-[#DCDCDC] transition-colors duration-300">
              SEND
            </button>
          </form>

          {/* Checkbox for Terms and Conditions */}
          <div className="flex items-center mt-4 justify-center">
            <input
              type="checkbox"
              id="terms"
              className="rounded-full bg-transparent border border-[#DCDCDC] w-5 h-5 cursor-pointer mr-2"
            />
            <label htmlFor="terms" className="text-[#DCDCDC]/75 font-nexa-light font-[700] text-[12px] leading-[15px]">
              Agree to our <span className="underline cursor-pointer">terms and conditions</span>
            </label>
          </div>
        </div>
      </div>

    </section>
  );
};

export default ContactUsSection;
