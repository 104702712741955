import React from 'react';
import CenterLineSection from '../components/CenterLineSection';
import ContactUsSection from '../components/ContactUsSection';
import FeaturesSection from '../components/FeaturesSection';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import NewsletterSection from '../components/NewsletterSection';
import OurResearch from '../components/OurResearch';
import TwoColumnSection from '../components/TwoColumnSection';

const Home: React.FC = () => {
  return (
    <>
      <HeroSection />
      <FeaturesSection />
      <TwoColumnSection />
      <CenterLineSection />
      <OurResearch />
      <NewsletterSection />
      <ContactUsSection />
      <Footer />
    </>
  );
};

export default Home;
