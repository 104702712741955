import React, { useEffect } from 'react';
import researchBg from '../assets/images/research-bg.png';
import { useLocation } from 'react-router-dom';

const TermsContent: React.FC = () => {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <section className="relative bg-[#101010] py-20 flex flex-col xs:flex-col md:flex-row items-center max-w-custom-1600 mx-auto overflow-visible items-center justify-center lg:mb-[110px]">

      {/* Column for background (Image) */}
      <div className=" min-h-[350px] flex flex-col justify-center mt-10 md:mt-0 relative">
        <div>
          <h3 className="text-center uppercase font-mentalist text-[#DCDCDC]/75 text-[7px] sm:text-[12px] md:text-[10px] leading-[10px] font-[700]">Revolutionary hub</h3>
          <h2 className="text-center font-nexa-heavy font-[800] text-[32px] sm:text-[48px] text-[#DCDCDC] mb-[30px]">
            Terms & Conditions
          </h2>
          <h3 className="hidden mt-[100px] text-center font-nexa-heavy font-[800] sm:text-[32px] text-[18px] md:text-[36px] text-[#DCDCDC] leading-[21px] sm:leading-[38px] md:leading-[25px] mb-2">Innovation</h3>
        </div>
        <img
          src={researchBg}
          alt="Hero"
          className='mt-[30px]'
          style={{ overflow: 'visible' }}
        />
      </div>

    </section>
  );
};

export default TermsContent;
