import React from 'react';
import researchIcon from '../assets/images/icon-research.png';
import updateIcon from '../assets/images/icon-update.png';
import innovationIcon from '../assets/images/icon-innovation.png';

const featuresData = [
  {
    image: researchIcon,
    title: 'Research',
    description: 'Unlock priceless insights in finance with our curated repository of top-tier research papers, guiding your way to success.',
  },
  {
    image: updateIcon,
    title: 'Updated',
    description: 'Stay ahead in the financial realm with real-time updates, ensuring you access the latest breakthroughs and market trends.',
  },
  {
    image: innovationIcon,
    title: 'Innovation',
    description: 'Providing Premium Financial Research to Istitutional players.',
  },
];

const FeaturesSection: React.FC = () => {
  return (
    <section id="about-us" className="py-16 bg-[#101010] text-white">
      <h2 className="text-center  font-nexa-heavy font-[800] text-[26px] sm:text-[64px] md:text-[48px] leading-[31px] sm:leading-[76px] md:leading-[58px] text-[#DCDCDC] mb-[20px] md:mb-[0px]">
        Why Edge Forge?
      </h2>
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-20">
        {featuresData.map((feature, index) => (
          <div key={index} className="flex flex-col items-center text-center p-5 px-4 rounded-lg">
            <img src={feature.image} alt={feature.title} className="w-[70px] sm:w-[200px] md:w-[128px] h-[70px] sm:h-[200px] md:h-[128px] object-cover -mb-2" />
            <h3 className="font-nexa-heavy font-[800] sm:text-[32px] text-[18px] md:text-[21px] text-[#DCDCDC] leading-[21px] sm:leading-[38px] md:leading-[25px] mb-2">{feature.title}</h3>
            <hr className="hidden md:flex border-t-2 border-white/25 w-[70%] my-4 mt-1" />
            <p className="font-nexa-light font-[400] text-[14px] sm:text-[21px] md:text-[16px] text-[#DCDCDC]/75 leading-[17px] sm:leading-[25px] md:leading-[20px]">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturesSection;
