import React from 'react';
import TermsContent from '../components/TermsContent';

const Terms: React.FC = () => {
  return (
    <>
      <TermsContent />
    </>
  );
};

export default Terms;
