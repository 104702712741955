import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import React from 'react';
import Slider from 'react-slick'; // Ensure this is imported
import researchOne from '../assets/images/1st-research.png';
import researchTwo from '../assets/images/2nd-research.png';
import researchThree from '../assets/images/3rd-research.png';
import arrowRight from '../assets/images/arrow-right.png';
import arrowLeft from '../assets/images/arrow-left.png';

const NextArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <div
    className="slick-arrow slick-next absolute !-left-[30px] sm:!-left-[120px] !top-[110px]"
    onClick={onClick}
    style={{ display: 'block', cursor: 'pointer' }}
  >
    <img src={arrowLeft} alt="Menu" className="h-[25px] w-[11px]" />
  </div>
);

const PrevArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => (
  <div
    className="slick-arrow slick-prev !-left-[30px] sm:!-left-[120px] !top-[35px]"
    onClick={onClick}
    style={{ display: 'block', cursor: 'pointer' }}
  >
    <img src={arrowRight} alt="Menu" className="h-[25px] w-[11px]" />
  </div>
);

const OurResearch: React.FC = () => {
  const posts = [
    {
      id: 1,
      image: researchOne,
      date: '27th Jul 2024',
      title: 'Quantum Analysis in Trading',
      excerpt: 'Embark on a data-driven journey through \'Quantum Analysis in Trading.\' Uncover...',
    },
    {
      id: 2,
      image: researchTwo,
      date: '3rd May 2024',
      title: 'Mindset Reprogramming for Traders',
      excerpt: 'Unleash the true potential of your trading journey with \'Mindset Reprogramming for...',
    },
    {
      id: 3,
      image: researchThree,
      date: '15th April 2024',
      title: 'Probabilistic Reasoning Mastery',
      excerpt: 'Navigate the complexities of the financial landscape armed with \'Probabilistic Reasoning..',
    },
    {
      id: 4,
      image: researchTwo,
      date: '3rd May 2024',
      title: 'Mindset Reprogramming for Traders',
      excerpt: 'Unleash the true potential of your trading journey with \'Mindset Reprogramming for...',
    },
    {
      id: 5,
      image: researchThree,
      date: '15th April 2024',
      title: 'Probabilistic Reasoning Mastery',
      excerpt: 'Navigate the complexities of the financial landscape armed with \'Probabilistic Reasoning..',
    },
    {
      id: 6,
      image: researchTwo,
      date: '3rd May 2024',
      title: 'Mindset Reprogramming for Traders',
      excerpt: 'Unleash the true potential of your trading journey with \'Mindset Reprogramming for...',
    },
    {
      id: 7,
      image: researchThree,
      date: '15th April 2024',
      title: 'Probabilistic Reasoning Mastery',
      excerpt: 'Navigate the complexities of the financial landscape armed with \'Probabilistic Reasoning..',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section id="research" className="slick-slider py-20 max-w-[1600px] mx-auto px-4">
      {/* Section Heading */}
      <h2 className="text-center font-nexa-heavy font-[800] text-[32px] sm:text-[48px] text-[#DCDCDC] mb-[70px]">
        Our Research:
      </h2>

      {/* Cards Slider */}
      <div className='relative left-[30px] sm:left-[110px] md:w-[103%]'>
        <Slider {...settings}>
          {posts.map((post) => (
            <div key={post.id} className="bg-[#101010] text-white p-2 rounded-lg shadow-lg flex flex-col pr-[20px] sm:pr-[150px] md:pr-6">
              {/* Card Image with Read More Button */}
              <div className="relative overflow-visible rounded-lg">
                <img src={post.image} alt={post.title} className="w-full h-[180px] object-cover rounded-lg border-[1px] rounded-[25px]" />
                <button className="absolute -bottom-5 -right-4 bg-[#DCDCDC] text-[#101010] font-mentalist font-[700] px-9 py-4 text-xs rounded-full shadow-md border-[1px] border-[#DCDCDC] hover:bg-[#101010] hover:text-[#DCDCDC] transition-colors duration-300">
                  Coming Soon
                </button>
              </div>

              {/* Post Date */}
              <p className="text-[#DCDCDC]/75 font-nexa-light font-[400] text-[12px] leading-[14.5px] mt-8">{post.date}</p>

              {/* Post Title */}
              <h3 className="text-[#DCDCDC] font-nexa-heavy font-[800] text-[21px] leading-[25px] mt-2">{post.title}</h3>

              <hr className="hidden md:flex border-t-2 border-white/25 w-[70%] my-4 mt-5" />

              {/* Post Excerpt */}
              <p className="text-[#DCDCDC]/75 font-nexa-light font-[900] text-[16px] leading-[19px] mt-2">
                {post.excerpt}
              </p>
            </div>
          ))}
        </Slider>
      </div>

      <button className="mt-16 flex justify-center items-center text-[#DCDCDC] font-nexa-light font-[700] text-[12px] leading-[14px] mt-auto underline pt-4 mx-auto">
        Coming Soon...
        <ChevronRight size={15} className="ml-[10px]" />
      </button>
    </section>
  );
};

export default OurResearch;
